<template>
    <div>
        <div class="prefooter">
            <p class="t-small">
                {{ $t('global.seguici_su') }}
            </p>
            <a href="https://www.instagram.com/asolo.prosecco/" target="_blank">
                <img src="../assets/images/loghi/instagram.svg">
            </a>
            <a href="https://www.facebook.com/asolo.prosecco/" target="_blank">
                <img src="../assets/images/loghi/facebook.svg">
            </a>
        </div>
        <div class="uk-grid uk-grid-collapse" uk-grid>
            <div class="t-small uk-width-5-6@m"> 
            {{ $t('global.footer') }} - <router-link to="/privacy" exact="" @click.native="scrollToTop">{{ $t('global.privacy') }}</router-link> -  
                <!-- <router-link to="/cookie" exact="" @click.native="scrollToTop">{{ $t('cookie.title') }}</router-link> -->
                <a href="#" class="ch2-open-settings-btn">{{ $t('cookie.title') }}</a>


            </div>
            <div class="credits t-small uk-width-1-6@m">
                <a href="#modal-credits" uk-toggle>
                    {{ $t('global.credits') }}
                </a>
            </div>
        </div>

        <!-- modal credits -->
        <div id="modal-credits" class="uk-flex-top"  uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <h2 class="t-xlarge">Credits</h2>
                <div class="contenuti t-medium" v-html="$t('global.credits_txt')"></div>
            </div>
        </div>

        
    </div>
</template>


<script>
    import { EventBus } from '../event-bus.js';

    export default {
        methods: {
            scrollToTop() {
                //vedi su app.vue
                EventBus.$emit('scrollToTop');
            },   
        }
    }
</script>