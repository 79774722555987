<template>

  <div id="app" :class="icoNavClass + ' ' + innerNavClass + ' ' + menuOpenClass" v-cloak>

    <!-- vedi component/header.vue--> 
    <myHeader></myHeader>
    
    <!-- contenuti --> 
    <transition name="router-anim">
      <router-view/>
    </transition>

    <!-- vedi component/footer.vue  -->
    <footer>
      <myFooter></myFooter>
    </footer>

    <!-- cookie 
    <cookie-law>
      <div slot-scope="props">
        <p v-html="$t('global.cookies_txt')"></p>
        <button class="skew" @click="props.accept" v-html="$t('global.cookies_ok')"></button>
      </div>
    </cookie-law> -->
  
  </div>
</template>

<style lang="scss">
  @import "./src/assets/scss/_common.scss";
  @import "./src/assets/scss/main.scss";
</style>

<script>
  //UIKIT
  import UIkit from 'uikit';
  import Icons from 'uikit/dist/js/uikit-icons';
  UIkit.use(Icons);

  //COMPONENTI
  import myHeader from './components/header.vue'
  import myFooter from './components/footer.vue'
  //import CookieLaw from 'vue-cookie-law'

  import { EventBus } from './event-bus.js';

  export default {
    name: 'App',
    components: {
      myHeader,
      myFooter,
      //CookieLaw,
    },
    data () {
        return { 
          sp: null,
          vh: null,
          icoNavClass: 'icoNav-positivo',
          innerNavClass: 'innerNav-positivo',
          menuOpenClass: '',
        }
    },

    methods: {
      updateScroll() {
        this.sp = window.scrollY;
        this.vh = window.innerHeight;
        //ico-menu positivo o negativo
        if(this.sp>(this.vh - 70)){
          this.icoNavClass='icoNav-negativo';
        } else {
          this.icoNavClass='icoNav-positivo';
        }
        //navigazione interna
        if(this.sp>(this.vh/2)){
          this.innerNavClass = 'innerNav-negativo'
        } else {
          this.innerNavClass = 'innerNav-positivo'
        }
      },
      
    },
    mounted() {
      window.addEventListener('scroll', this.updateScroll);
      
      //scroll to top
      EventBus.$on('scrollToTop', () => {
        window.setTimeout(() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              }); 
            },100);
      });

      //menu aperto/chiuso
      EventBus.$on('menu', (isOpen) => {
        if(isOpen){
          this.menuOpenClass = "menuAperto";
        } else {
          this.menuOpenClass = "";
        }
      });

    }
  }
</script>
