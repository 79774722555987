import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'


//multilingua
import i18n from './i18n'

//metadata
import Meta from 'vue-meta'
Vue.use(Meta)

//animazione oggetti in viewport
import ScrollAnimation from './directives/scrollanimation'
Vue.directive('scrollanimation', ScrollAnimation);


Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
