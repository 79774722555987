<template>
  <div class="page home">
    <!-- navigazione interna -->
    <div class="innerNav back">
      <router-link :to="$t('url.sostenibilita')" exact="" tag="a" @click.native="scrollToTop">{{ $t('menu.sostenibilita') }}</router-link>
    </div>
    <div class="innerNav next">
      <router-link :to="$t('url.asolo')" exact="" tag="a" @click.native="scrollToTop">{{ $t('menu.asolo') }}</router-link>
    </div>
    
    <!-- banner -->
      <div class="banner" :class="'load-'+isLoad + ' video-'+displayVideo">
        <h1 v-html="$t('home.title')" class="t-xlarge"></h1>

        <!-- su DESKTOP -->
        <template v-if="!isMobile">
          <img src="../assets/images/header/home-header.jpg" @load="caricatoCover" alt="" class="uk-img uk-animation-kenburns">
          <!-- viene compilato dopo aver caricato l'immagine di cover -->
          <video id="video" autoplay="true" preload="auto" muted="true" loop="true" :class="'video-'+displayVideo">
            <source v-if="isLoad" src="../assets/video/home.mp4" type="video/mp4" />
          </video>
        </template>
        
        
        <!-- su MOBILE -->
        <template v-else>
        
          <!-- slider mobile -->
          <div class="mobile-slider uk-position-relative" tabindex="-1" uk-slideshow="ratio:false; animation: fade; autoplay: true; autoplay-interval: 5000; velocity:.1; pause-on-hover: false">
            <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: true">
                <li>
                    <div class="uk-position-cover uk-animation-kenburns uk-transform-origin-center-center">
                      <img src="../assets/images/header/home-header-mobile-1.jpg" alt="" class="mobile" uk-cover @load="caricatoCoverMobile">
                    </div>
                </li>
                <li>
                  <div class="uk-position-cover uk-animation-kenburns uk-transform-origin-center-center">
                    <img src="../assets/images/header/home-header-mobile-2.jpg" alt="" class="mobile" uk-cover @load="caricatoCoverMobile">
                  </div>
                </li>
                <li>
                  <div class="uk-position-cover uk-animation-kenburns  uk-transform-origin-center-center">
                    <img src="../assets/images/header/home-header-mobile-3.jpg" alt="" class="mobile" uk-cover @load="caricatoCoverMobile">
                  </div>
                </li>
            </ul>
          </div>

        </template>


      </div>

    <span class="scroll"></span>

    <div class="contenuti" v-if="isLoad">

      
      <!-- sottotitolo -->
      <section class="subtitle">
        <div class="row" v-scrollanimation>
          <h2 class="t-large" v-html="$t('home.subtitle')"></h2>
        </div>
      </section>

      <section class="por">
        <a href="https://www.asolomontello.it/am2020/wp-content/uploads/2020/11/fondo_europeo.pdf" target="_blank">
          <div class="r1">
            <img src="../assets/images/psr.jpeg" alt="logo-psr">
          </div>
          <div class="r2">
            <div class="row uk-grid uk-grid-collapse" uk-grid>
              <div class="img uk-width-auto@m">
                <img src="../assets/images/logo-veneto.jpeg" alt="logo-veneto">
              </div>
              <div class="txt uk-width-expand@m">
                <p> Iniziativa pubblicitaria finanziata dal Programma di Sviluppo Rurale per il Veneto 2014 - 2020<br> Organismo responsabile dell'informazione: CONSORZIO VINI ASOLO MONTELLO<br> Autorità di gestione: Regione del Veneto, Direzione AdG FEASR e Foreste</p>
              </div>
            </div>
          </div>
        </a>
      </section>




      <!-- gallery -->
      <section class="gallery">
        <div class="row" v-scrollanimation>
          <div class="uk-position-relative" tabindex="-1" uk-slideshow="min-height: 350; animation: fade; autoplay: true; autoplay-interval: 5000; pause-on-hover: false">
            <ul class="uk-slideshow-items">
                <li>
                    <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                      <img src="../assets/images/home/galleryA-1.jpg" alt="" uk-cover>
                    </div>
                </li>
                <li>
                  <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                    <img src="../assets/images/home/galleryA-3.jpg" alt="" uk-cover>
                  </div>
                </li>
                <li>
                  <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                    <img src="../assets/images/home/galleryA-2.jpg" alt="" uk-cover>
                  </div>
                </li>
            </ul>
          </div>
        </div>
      </section>


      

      <!-- titolo a sx e testo a dx -->
      <section class="txt">
        <div class="row uk-grid uk-grid-collapse">
          <div class="title uk-width-1-3@l" v-scrollanimation>
            <h3 class="t-xlarge" v-html="$t('home.title1')"></h3>
          </div>
          <div class="txt uk-width-2-3@l" v-scrollanimation>
            <p class="t-medium"  v-html="$t('home.txt1')"></p>
          </div>
        </div>
      </section>




       <!-- img singola  -->
    <section class="img">
      <div class="row" v-scrollanimation>
        <div class="uk-overflow-hidden">
          <img src="../assets/images/home/galleryB-1.jpg" alt="" uk-scrollspy="cls: uk-animation-kenburns; repeat: true">
        </div>
      </div>
    </section>

      <!-- titolo a sx e testo a dx -->
      <section class="txt">
        <div class="row uk-grid uk-grid-collapse">
          <div class="title uk-width-1-3@l" v-scrollanimation>
            <h3 class="t-xlarge" v-html="$t('home.title2')"></h3>
          </div>
          <div class="txt uk-width-2-3@l" v-scrollanimation>
            <p class="t-medium"  v-html="$t('home.txt2')"></p>
          </div>
        </div>
      </section>

    </div>

  <!-- accrocchio per metaTitle e meta-description in lingua -->
  <div id="metaTitle">{{$t('home.meta-title')}}</div>
  <div id="metaDescription">{{$t('home.meta-description')}}</div>

</div>
</template>


<script>

  import { EventBus } from '../event-bus.js';
  
    export default {
      data: function() {
        return {
          //banner
          isLoad: false,
          displayVideo: false,
          //
          isMobile:false,
          mobileW:960,
          metaTitle: '',
          metaDescription:'',
          num:1,

        }
      },
      methods: {
        //richiamata al click dal navigatore interno (freccine destra e sinistra)
        //vedi su app.vue
        scrollToTop() {
          EventBus.$emit('scrollToTop');
        },
        //banner desktop
        caricatoCover() {
          //una volta caricata l'immagine principale
          this.isLoad = true;
          this.$nextTick(function () {
              window.setTimeout(() => {
                  //dopo qualche secondo visualizzo il video e il resto del contenuti
                  this.displayVideo = true;        
              },2000);
          })
        },
        //banner mobile
        caricatoCoverMobile(){
          this.num++;
          if(this.num>3){
            this.isLoad = true;
          }
        },
        checkWW() {
          if(window.innerWidth> this.mobileW){
            this.isMobile = false;
          } else {
            this.isMobile = true;
          }
        }
      },
      mounted: function(){        
        window.addEventListener('resize', this.checkWW);
        this.checkWW();
        this.metaTitle = document.querySelector('#metaTitle').innerText;
        this.metaDescription = document.querySelector('#metaDescription').innerText;
        //controllo lingua e risetto
        if(this.$route.name.includes("-en")){
          //console.log("sono in inglese");
          this.$i18n.locale = "en";
        } 
      },
      metaInfo: function(){
        return{
          title: this.metaTitle,
          meta: [
            {
              name:"description",
              content: this.metaDescriprion,
            }
          ]
        }
      }
    }
  
  </script>
