<template>
    <header>
        <div class="header-container uk-grid uk-grid-collapse" uk-grid>
            <div class="cambioLingua uk-width-1-5 uk-width-1-6@m">
                <ul>
                    <li v-for="locale in locales" :key="locale" @click="cambiaLingua(locale)" :class="{ active: linguaAttiva === locale }">{{ locale }}</li>
                </ul>
            </div>
            <div class="logo uk-width-3-5 uk-width-expand@m">
                <router-link :to="$t('url.homepage')" @click.native="scrollToTop">
                    <img src="../assets/images/loghi/logo-asolo-prosecco@2x.png" class="uk-img">
                </router-link>
            </div>
            <div class="uk-width-1-5 uk-width-1-6@m">
                
            </div>
        </div>

        <div class="icoMenu">
            <div :class="{ open: menuOpen }" @click="myMenu('toggle')">
                <span/>
                <span/>
            </div>
        </div>

        <div class="menu" :class="{ open: menuOpen }">
            <div id="nav">
                <ul class="main"> 
                    <router-link :to="$t('url.homepage')" active-class="active" exact="" tag="li" @click.native="scrollToTop">{{ $t('menu.homepage') }}</router-link>
                    <router-link :to="$t('url.asolo')" active-class="active" exact="" tag="li" @click.native="scrollToTop">{{ $t('menu.asolo') }}</router-link>
                    <router-link :to="$t('url.denominazione')" active-class="active" exact="" tag="li" @click.native="scrollToTop">{{ $t('menu.denominazione') }}</router-link>
                    <router-link  :to="$t('url.territorio')" active-class="active" exact="" tag="li" @click.native="scrollToTop">{{ $t('menu.territorio') }}</router-link>
                    <router-link  :to="$t('url.sostenibilita')" active-class="active" exact="" tag="li" @click.native="scrollToTop">{{ $t('menu.sostenibilita') }}</router-link>
                </ul>
                <ul class="secondario">
                    <li><a href="https://www.asolomontello.it/i-soci/" target="_blank">{{ $t('menu.produttori') }}</a></li>
                    <li><a href="https://www.asolomontello.it/" target="_blank">{{ $t('menu.consorzio') }}</a></li>
                    <li><a href="https://www.dropbox.com/sh/687w7f7f8f43ntf/AADpgv1WXUYP0LXaMpFCkn-4a?dl=0" target="_blank">{{ $t('menu.cartellaStampa') }}</a></li>
                    <li><a href="mailto:info@asoloprosecco.com">{{ $t('menu.contatti') }}</a></li>
                </ul>

                <div class="social">
                    <p class="t-small uk-text-uppercase">
                        {{ $t('global.seguici_su') }}
                    </p>
                    <a href="https://www.instagram.com/asolo.prosecco/" target="_blank">
                        <img src="../assets/images/loghi/instagram-white.svg">
                    </a>
                    <a href="https://www.facebook.com/asolo.prosecco/" target="_blank">
                        <img src="../assets/images/loghi/facebook-white.svg">
                    </a>
                </div>
            </div>

            

        </div>



    </header>
</template>


<script>
    import { EventBus } from '../event-bus.js';

    export default {
        methods: {
            cambiaLingua(lang){
                if(this.$i18n.locale !== lang){
                    this.$i18n.locale = lang;
                    this.linguaAttiva = lang;
                }
            },
            myMenu(action){
                if(action === 'toggle'){
                    this.menuOpen = !this.menuOpen;
                } else {
                    this.menuOpen = false;
                }
                
            },  
            scrollToTop() {
                //vedi su app.vue
                EventBus.$emit('scrollToTop');
                this.menuOpen = false;
            },
            
        },
        data () {
            return { 
                linguaAttiva:this.$i18n.locale,
                locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
                menuOpen: false,
            }
        },
        watch: {
            menuOpen: function () {
                //vedi in app
                EventBus.$emit('menu', this.menuOpen);
            }
        }
    }
</script>


