import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)
  const routes = [
  {
    //IT
    path: '/',
    name: 'Homepage',
    component: Home
  },
  {
    //EN
    path: '/home',
    name: 'Homepage-en',
    component: Home
  },
  {
    //IT
    path: '/asolo',
    name: 'Asolo',
    component: () => import(/* webpackChunkName: "asolo" */ '../views/Asolo.vue')
  },
  {
    //EN
    path: '/asolo-town',
    name: 'asolo-en',
    component: () => import(/* webpackChunkName: "asolo" */ '../views/Asolo.vue')
  },
  {
    //IT
    path: '/denominazione',
    name: 'Denominazione',
    component: () => import(/* webpackChunkName: "denominazione" */ '../views/Denominazione.vue')

  },
  {
    //EN
    path: '/asolo-prosecco',
    name: 'Denominazione-en',
    component: () => import(/* webpackChunkName: "denominazione" */ '../views/Denominazione.vue')
  },
  {
    //IT
    path: '/territorio',
    name: 'Territorio',
    component: () => import(/* webpackChunkName: "territorio" */ '../views/Territorio.vue')
  },
  {
    //EN
    path: '/region',
    name: 'Territorio-en',
    component: () => import(/* webpackChunkName: "territorio" */ '../views/Territorio.vue')
  },
  {
    //IT
    path: '/sostenibilita',
    name: 'Sostenibilita',
    component: () => import(/* webpackChunkName: "sostenibilita" */ '../views/Sostenibilita.vue')
  },
  {
    //EN
    path: '/sustainability',
    name: 'Sostenibilita-en',
    component: () => import(/* webpackChunkName: "sostenibilita" */ '../views/Sostenibilita.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import(/* webpackChunkName: "cookie" */ '../views/Cookie.vue')
  }
]

const router = new VueRouter({
  mode : 'history',
  routes,
  
})

//analytics
Vue.use(VueGtag, {
  config: { id: "UA-167901456-1" },
  includes: [
    { id: 'UA-154208562-1' }
  ],
  //enabled: false
}, router);


export default router
